.ol-map {
    height: 100%;
    width: 100%;
    margin: 0;
}
#tooltip {
    background-color: #fff;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
}
