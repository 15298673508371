@import '../../_variables.scss';

.customRadio {
    transform: translateY(2px); 
    
    &:hover {
        cursor: pointer;
    }

    &:checked,
    &:not(:checked) {
        position: absolute;
        left: -9999px;

        & + label {
            position: relative;
            padding-left: 28px;
            cursor: pointer;
            line-height: 20px;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                width: 16px;
                height: 16px;
                border: 1px solid map-get($neutral-colors, "200");
                border-radius: 100%;
                background: map-get($theme-colors, "light");
                transform: translateY(-50%);
            }
            &:after {
                content: '';
                position: absolute;
                left: 4px;
                top: 50%;
                width: 8px;
                height: 8px;
                background: currentColor;
                border-radius: 100%;
                transform: translateY(-50%);
                transition: all 0.2s ease;
            }
        }
    }
    &:not(:checked) + label:after {
        opacity: 0;
        transform: translateY(-50%) scale(0);
    }
    &:checked + label:after {
        opacity: 1;
        transform: translateY(-50%) scale(1);
    }
    
    &__label {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        margin: 0 0.5rem;
    
        &:hover {
            cursor: pointer;
        }
    }
}
