@import '../../_variables.scss';

.widget {
    &__toggle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        width: 100%;
        font-family: map-get($font, "medium");
        color: map-get($theme-colors, "dark");
        background-color: map-get($theme-colors, "light");
        border: none;
        border-bottom: 1px solid map-get($neutral-colors, "50");

        &:hover {
            text-decoration: none;
            background-color: map-get($neutral-colors, "50");
        }
    }

    &__body {
        padding: map-get($size, "s");
        border-bottom: 1px solid map-get($neutral-colors, "50");
    }
}

.widgetList {
    list-style: none;
    padding: 0;
    margin: 0.5rem 0;

    &__item {
        padding: 0.5rem 0;
        font-size: map-get($font-size, "paragraph-s");

        &--all {
            margin-right: 1.8rem;
        }
    }    
    &__toggle {
        padding-right: 0.2rem;
        padding-left: 0rem;
        color: map-get($theme-colors, "dark");
    }
    &__name,
    &__value {
        color: map-get($theme-colors, "dark");
        font-family: map-get($font, "medium");
    }

    .icon {
        height: map-get($size, "m");
        width: map-get($size, "m");
        margin: 0 0.5rem 0 0;
    }

    .lineChart {
        margin-top: 0.5rem;
    }

    &--sub {
        ul {
            list-style: none;
            padding: 0;
        }
        li {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            padding: 0 1.8rem 0.5rem 0;
            font-size: map-get($font-size, "paragraph-xs");
        }
        .lineChart {
            margin-top: 0.25rem;
        }
    }
}

.livcyList {
    &__name {
        color: map-get($theme-colors, "dark");
        margin-left: 0.5rem;

        &:first-child {
            color: map-get($theme-colors, "primary");
            margin: 0;
        }
    }
    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        font-family: map-get($font, "medium");
        font-size: map-get($font-size, "paragraph-s");
        color: map-get($theme-colors, "primary");
        border-radius: 4px;
        cursor: pointer;
        padding: 0 0 0 .75rem;

        .circularChart {
            margin-right: 1.1rem;
        }

        &--livcy {
            background-color: map-get($tints-cyan-colors, "50");
            font-family: map-get($font, "medium");
            font-size: 1rem;

            .circularChart {
                font-size: 1.25rem;
                margin-right: 0.5rem;
            }
        }

        label {
            padding: 1rem 0;
        }

        &:hover {
            background-color: rgba(map-get($theme-colors, "dark"), 0.02);
            cursor: pointer;
        }

        .icon {
            height: map-get($size, "m");
            width: map-get($size, "m");

        }
    }
}