@import './_variables.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import "~gotham-fonts/css/gotham-rounded.css";

body {
  font-family: map-get($font, "book");
  font-size: map-get($font-size, "paragraph-m");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: map-get($font, "medium");
  font-weight: normal;
  color: map-get($theme-colors, "primary");
}
h1, .h1 {
  font-size: map-get($font-size, "heading-xl");
}
button, th, strong, dt {
  font-family: map-get($font, "medium");
  font-weight: 500;
}

.table th, .table td {
  padding: 0 !important;
  border-top: 0;
}

.filter {
  border-radius: 4px;
  border: 1px solid #006fad;
  padding: 1px;
  margin: 0.1em;
  .filter-name {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}

.filter:first-child {
  margin-left: 0;
}

.card hr {
  padding: 0;
}

.full-height {
    min-height: 100vh;
}
.sketch-layer {
    display: none;
}
.widget-col {
    max-width: 450px;
}

.dense {
  font-size: map-get($font-size, "paragraph-s");
  padding: 0.25rem 0.75rem;
  border: none;
}

td.indented::before {
  margin-left: 1em !important;
  content: ''
}

.editable-legend {
  max-width: 60px;
  border: 0;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: 0px;
  text-align: center;
  border-bottom: 1px solid black;
}

// Button reset
button {
  border: none;
  background: transparent;
  color: inherit;
}

// Popover
.popover {
  padding: 0.5rem 0;
  border: none;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);

  .arrow::before {
    border: none;
  }

  &-header {
    background-color: transparent;
    border-bottom: none;
    color: map-get($theme-colors, "primary");
  }
}

// Box
.box {
  position: relative;
  border-radius: 8px;
  padding: 24px 24px 32px;
  margin-bottom: 16px;
  box-shadow: 0 1px 2px rgba(#273943, 0.02), 
              0 2px 4px rgba(#273943, 0.02), 
              0 4px 8px rgba(#273943, 0.02), 
              0 8px 16px rgba(#273943, 0.02),
              0 16px 32px rgba(#273943, 0.02), 
              0 32px 64px rgba(#273943, 0.02);

  &__header {
    margin-bottom: 16px;
    text-align: center;
  }
  &__title {
    color: map-get($theme-colors, "dark");
    font-size: map-get($font-size, "paragraph-l");
    margin: 0;
  }
}

@page {
    size: A4 portrait;
    /*size: 297mm 210mm;*/ /* landscape */
    /* you can also specify margins here: */
    margin: 0;
    /* margin:25mm;*/
    /*margin-right: 45mm;*/ /* for compatibility with both A4 and Letter */
}

@media print {
  @page {
    size: A4 portrait;
    /*size: 297mm 210mm;*/ /* landscape */
    /* you can also specify margins here: */
    margin: 0;
    /* margin:25mm;*/
    /*margin-right: 45mm;*/ /* for compatibility with both A4 and Letter */
  }
  html, body, #root {
    width: 210mm;
    min-width: 210mm !important;
    max-width: 210mm !important;
    height: 297mm;
    /*overflow: visible;*/
  }
  .btn {
    display: none !important;
  }
  .navbar {
    display: none !important;
  }
  #footer {
    display: none !important;
  }
  #reports {
      padding: 0 !important;
      margin: 0 !important;
  }
  .card {
    overflow: auto !important;
    margin: initial !important;
    /*padding: 0 !important;*/
    box-shadow: none !important;
    border-radius: 0 !important;
    border: none !important;
    width: initial;
    min-height: initial;
    page-break-after: always;
  }
  .report {
    page-break-after: always;
  }
}