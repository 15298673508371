@import '../../_variables.scss';

#reports {
    font-size: 10pt;

    .icon-circle {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        text-align: center;
        display: flex;
        align-items: center;
        min-width: 50px;
        .icon {
            display: block;
            margin: auto;
            width: 24px;
            height: 24px;
            color: currentColor;
        }
    }
    .commerce {
        color: map-get($livcy-colors, "commerce");
        &.icon-circle {
            background: lighten(map-get($livcy-colors, "commerce"), 30%);
        }
    }
    .health {
        color: map-get($livcy-colors, "health");
        &.icon-circle {
            background: lighten(map-get($livcy-colors, "health"), 30%);
        }
    }
    .cycling {
        color: map-get($livcy-colors, "traffic");
        &.icon-circle {
            background: lighten(map-get($livcy-colors, "traffic"), 54%);
        }
    }
    .tree {
        color: map-get($livcy-colors, "parks");
        &.icon-circle {
            background: lighten(map-get($livcy-colors, "parks"), 38%);
        }
    }
    .exercise {
        color: map-get($livcy-colors, "sports");
        &.icon-circle {
            background: lighten(map-get($livcy-colors, "sports"), 40%);
        }
    }
    .film {
        color: map-get($livcy-colors, "culture");
        &.icon-circle {
            background: lighten(map-get($livcy-colors, "culture"), 35%);
        }
    }

    .livcy-index-img {
        position: relative;
        div {
            text-align: center;
            width: 100%;
            position: absolute;
            top: 8%;
            font-family: 'Gotham Rounded Medium', 'Open Sans', sans-serif;
            font-size: 70px;
            color: white;
        }
    }

    .report {
        flex-wrap: wrap;
        display: flex;
    }
    // https://stackoverflow.com/questions/16649943/css-to-set-a4-paper-size
    padding-top: 1em;

    .card {
        box-shadow: 0px 5px 5px lightgray;
        width: 210mm;
        min-height: 297mm;
        padding: 1cm;
        margin: 1cm auto;
        border: 1px #D3D3D3 solid;
        border-radius: 5px;
        background: white;
        display: flex;
    }
    .map {
        width: 100%;
        height: 400px;

        #tooltip {
            display: none;
        }
    }
    .card-body,.card-text {
        display: flex;
        flex-direction: column;
    }

    .card:first-child {
        padding: 0cm;
        background: url("../../assets/report/report-coverbg.jpg") no-repeat;
        background-size: cover;
        h1 {
            display: inline-block;
            background: map-get($theme-colors, "primary");
            color: white;
            padding: 1cm;
            padding-bottom: 0.5cm;
            margin: 0;
            font-size: 30pt;
            width: 80%;
        }
        h2 {
            display: block;
            background: map-get($theme-colors, "primary");
            color: white;
            margin: 0;
            padding-left: 1cm;
            padding-bottom: 1cm;
            font-size: 16pt;
            width: 80%;
        }
        .ramboll-logo {
            width: 230px;
            height: 70px;
            display: inline-block;
            background: url("../../assets/report/logo-white-ramboll-tagline.svg") no-repeat map-get($theme-colors, "primary");
            background-position-x: 1cm;
            background-position-y: 0.5cm;
            background-size: 60%;
        }
    }
        .icon {
            height: 100%;
            width: 100%;
            color: black;
        }
    .card-title {
        font-family: map-get($font, "medium");
        font-size: 28pt;
        color: map-get($theme-colors, "primary");
        min-height: 5em;

        .livcy-index {
            font-size: 44pt;
            display: flex;
            margin-left: 0.2em;
            margin-right: 0.2em;
        }
        .livcy-index-desc {
            font-family: map-get($font, "book");
            font-size: 10pt;
            display: inline-block;
            max-width: 200px;
            vertical-align: baseline;
            display: flex;
        }
        .livcy-heading {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        
    }
    .card-title.summary {
        min-height: auto;
    }
    .card-text {
        flex-grow: 1;
    }
    .row {
        flex-grow: 1;
    }
    .row.flex-shrink {
        flex-grow: 0;
        flex-shrink: 1;
    }
    .col {
        display: flex;
        flex-direction: column;
        section {
            flex-grow: 1;
            flex-shrink: 0;
        }
        section.takeaway {
            flex-grow: 0;
            flex-shrink: 1;
            border-top: 2px solid map-get($theme-colors, "primary");
            color: map-get($theme-colors, "primary");
            .takeaway-highlight {
                display: block;
                font-family: map-get($font, "medium");
                white-space: nowrap;
                font-size: 36pt;
            }
        }
        section.map {
            flex-grow: 0;
            flex-shrink: 1;
        }
        section.map-grow {
            flex-grow: 1;
            flex-shrink: 0;
        }
    }
    section > h6 {
        font-family: map-get($font, "medium");
        text-transform: uppercase;
        color: map-get($theme-colors, "primary");
    }
    .report-table {
        tr, th {
            border-bottom: 2px solid map-get($theme-colors, "primary");
        }
        td, th {
            padding: 0.5em !important;
        }

        .table-icon-text {
            display: flex;
            flex-direction: row;
            align-items: center;
            .icon-circle {
                margin-right: 0.3em;
            }
            .title {
                display: flex;
            }
        }
    }
    .card-blue {
        padding: 3cm;
        background: #05326E;
        color: white;
        font-family: map-get($font, "medium");

        h2, h3, h4 {
            color: map-get($theme-colors, "primary");
        }
        h3, h4 {
            font-size: 10pt;
        }
        .footer {
            margin-top: 1rem;
            h2 {
                color: map-get($theme-colors, "light");
            }
        }
    }
}

// Livcy list
.livcy-list {
    &__container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: map-get($theme-colors, "primary");
    }
    &__index {
        position: relative;
        justify-content: center;
        padding-bottom: 2rem;
        font-size: 3rem;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            height: 2rem;
            width: 2px;
            background-color: map-get($theme-colors, "primary");
        }
    }
    &__item {
        align-items: center;
        text-align: center;
    }
    &__title {
        font-family: map-get($font, "medium");
        color: map-get($theme-colors, "dark");
        margin-top: 8px;
        line-height: 1.2;
    }
}

.livcy-list--index {
    position: relative;
    padding-top: 2rem;
    margin-bottom: 2rem;
    
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 2px;
        width: 84%;
        background-color: map-get($theme-colors, "primary");
    }

    .livcy-list__item {
        align-items: center;
        text-align: center;
        
        &::after {
            content: '';
            position: absolute;
            top: -2rem;
            left: 50%;
            height: 2rem;
            width: 2px;
            background-color: map-get($theme-colors, "primary");
        }
    }
}