/*.row div {
    padding: 0;
}*/

.open-left > .dropdown-menu {
    left: auto;
    right: 0;
}
#content {
  display: flex;
  flex: 1 0 auto;
}

#footer {
  flex-shrink: 1;
}
#app-container {
  /*min-height: 100vh;*/
  height: 100vh;
  display: flex;
  flex-direction: column;
}

