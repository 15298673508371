@import '../../_variables.scss';

.sidebar {
    position: relative;
    background: map-get($theme-colors, "light");
    font-size: map-get($font-size, "paragraph-s");
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);

    &__toggle.btn {
        position: absolute;
        top: 50%; 
        right: -32px;
        border-radius: 0 2px 2px 0;
    }
}

.livcybox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: map-get($tints-cyan-colors, "50");
    border-radius: 8px;
    margin: 0.5rem 0 0.5rem 0;
    color: map-get($theme-colors, "primary");
    text-align: center;
    font-size: 0.8rem;

    .circularChart__value {
        font-size: 2rem;
    }
}

.selected-area {
    display: flex;
    flex-direction: column;
    color: map-get($theme-colors, "dark");
    padding: 1rem;
    border-radius: 8px;
    margin: 0.5rem 0;

    svg {
        margin-top: 0.2rem;
        height: 24px;
        width: 24px;
    }
}

.selected-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin: 0;

    dt {
        font-size: map-get($font-size, "paragraph-xs");
        font-family: map-get($font, "medium");
        text-transform: uppercase;
        font-weight: normal;
        margin: 0.2rem 0 0 0;
    }
    
    dd {
        font-size: map-get($font-size, "paragraph-s");
        margin: 0;

        &:first-of-type {
            font-size: map-get($font-size, "paragraph-m");
            font-family: map-get($font, "medium");
            color: map-get($theme-colors, "primary");
            font-size: 1.1rem;
            margin-bottom: 0;
        }
    }
}