// colors
$theme-colors: (
    "primary": #009df0,
    "secondary": #808080,
    "success": #3aa551,
    "danger": #c63418,
    "warning": #f09c00,
    "info": #006fad,
    "light": #fff,
    "dark": #273943
);
$tints-cyan-colors: (
    "400": #24B3FF,
    "300": #57C5FF,
    "200": #8AD6FF,
    "100": #BDE8FF,
    "50": #F0FAFF
);
$neutral-colors: (
    "900": #1A1A1A,
    "800": #333333,
    "700": #4D4D4D,
    "600": #666666,
    "500": #808080,
    "400": #999999,
    "300": #B2B2B2,
    "200": #CCCCCC,
    "150": #D9D9D9,
    "100": #E5E5E5,
    "50": #F2F2F2
);
$livcy-colors: (
    "livcy": #009df0,
    "commerce": #d675b3,
    "health": #8b5eeb,
    "traffic": #13b398,
    "parks": #68ba5d,
    "sports": #f5c020,
    "culture": #cb616b
);

// Typography
$font: (
    "light": (Gotham Rounded Light, Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif),
    "book": (Gotham Rounded Book, Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif),
    "medium": (Gotham Rounded Medium, Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif),
);
$font-size: (
    "paragraph-l": 20px,
    "paragraph-m": 16px,
    "paragraph-s": 14px,
    "paragraph-xs": 12px,
);
$size: (
    "xxl": 28px,
    "xl": 24px,
    "l": 20px,
    "m": 16px,
    "s": 8px,
    "xs": 4px,
    "xxs": 2px,
);
