@import '../../_variables.scss';

.lineChart {
    position: relative;
    overflow: hidden;
    width: 100%;

    &__shadow {
        background-color: currentColor;
        opacity: 0.2;
        border-radius: map-get($size, "xs");
    }

    &__line {
        background-color: currentColor;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: map-get($size, "xs");
        transition: width 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
}
