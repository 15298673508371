@import '../../_variables.scss';

.circularChart {
    display: block;
    margin: 0.5rem;
    max-width: 100%;
    fill: transparent;
    transform: rotate(-90deg);

    &__shadow {
        fill: none;
        opacity: 0.2;
    }
      
    &__circle {
        fill: none;
        transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    
    &__value {
        text-anchor: middle;
        dominant-baseline: middle;
        fill: currentColor;
        font-family: map-get($font, "medium");
        transform: rotate(90deg) translateY(1px);
        transform-origin: center;
    }
}
