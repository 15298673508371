@import '../_variables.scss';

.hero {
  background-color: map-get($theme-colors, "primary");
  color: map-get($theme-colors, "light");
  text-align: center;
  padding-bottom: 3em;
}

.hero h1 {
  color: map-get($theme-colors, "light");
  font-weight: 600;
  font-size: 26pt;
  padding: 1em;
  padding-top: 2em;
  margin: 0;
}

#app {
  background: url("../assets/A380-Flyover.jpg") no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
