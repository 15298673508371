.mapview-container {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    .map-container {
        flex-grow: 1;
    }

    .card {
        width: auto !important;
        min-width: auto !important;
        height: auto !important;
        min-height: auto !important;
        margin: 0 !important;
        padding: 0 !important;
    }
    .legend-header {
        margin-bottom: 0.4em;
        flex-shrink: 1;
        display: flex;
        font-size: 8pt;
        flex-direction: column;

        .title {
            flex-shrink: 1;
        }
        .steps {
            flex-grow: 1;

            .legend-inline {
                display: inline-block;
                margin-left: 0.5em;
            }
            .legend-inline:first-of-type {
                margin-left: 0;
            }
        }
    }
    .loading-indicator {
        margin-top: 0.2em !important;
        padding: 0.5em !important;
    }
}