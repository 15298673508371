.flag-icon {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1.33333333em;
    line-height: 1em;
    margin-right: 0.33333333em;
}

.flag-icon-background {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}

.flag-icon:before {
    content: "\00a0";
}

.flag-icon-en {
    background-image: url(../../assets/flags/gb.svg);
}

.flag-icon-fi {
    background-image: url(../../assets/flags/fi.svg);
}

.flag-icon-se {
    background-image: url(../../assets/flags/se.svg);
}
